<template>
  <div class="apartments">
    <Header :type="this.headerTypes.APARTMENTS" title="Discover our apartments"/>
    <div class="apartments-content">
      <b-container>
        <b-row class="justify-content-between mb-3">
          <b-col cols="6" md="auto" >
            <RFSelect class="mt-0" :full-width="false" :label="$t('Sort by')" background="#DADBD7" :list="[{ label: 'Price low to high', value: 'asc', function: true }, { label: 'Price high to low', value: 'desc', function: true }]" v-model="sorting" @function="sortList(sorting)" />
          </b-col>
          <b-col cols="auto" class="d-flex align-items-center mt-4 mt-md-0">
            <div class="toggle-map mr-3" @click="showFilters = !showFilters">
              <transition name="fade">
                <span class="show" v-if="!showFilters">{{$t('Add Filters')}}</span>
                <span class="hide" v-else>{{$t('Hide Filters')}}</span>
              </transition>
            </div>
            <div class="toggle-map" @click="toggleMap = !toggleMap">
              <transition name="fade">
                <span class="show" v-if="!toggleMap">{{$t('Show Map')}}</span>
                <span class="hide" v-else>{{$t('Hide Map')}}</span>
              </transition>
            </div>
          </b-col>
        </b-row>
        <b-row class="justify-content-center mb-5" v-if="showFilters">
          <b-col cols="12" md="6" class="mt-3 mt-md-0 px-0 pr-md-3">
            <div class="booking-form-container">
              <ValidationObserver ref="validator">
                <form class="booking-form" @submit.prevent="search">
                  <b-row class="d-flex justify-content-between align-items-center">
                    <b-col cols="12" lg="4">
                      <div class="booking-label">{{$t('Arrival')}}</div>
                    </b-col>
                    <b-col cols="12" lg="6">
                      <RFValidation rules="required">
                        <RFMonthPicker v-model="searchParams.startDate" :min="currentMonth" :special-format="specialDateFormat" />
                      </RFValidation>
                    </b-col>
                  </b-row>
                  <b-row class="d-flex justify-content-between align-items-center">
                    <b-col cols="12" lg="4">
                      <div class="booking-label">{{$t('Departure')}}</div>
                    </b-col>
                    <b-col cols="12" lg="6">
                      <RFValidation rules="required">
                        <RFMonthPicker v-model="searchParams.endDate" :min="currentMonth" :special-format="specialDateFormat" :ending-date="true" />
                      </RFValidation>
                    </b-col>
                  </b-row>
                  <b-row class="d-flex justify-content-center">
                    <b-col cols="6">
                      <RFButton class="my-3" :label="$t('Filter')" full-width background="#74776D" color="#fff" border-color="transparent"/>
                    </b-col>
                  </b-row>
                </form>
              </ValidationObserver>
            </div>
          </b-col>
        </b-row>
        <div class="admin-loading" v-if="loading">
          <RFLoader class="mr-3" color="#24425B" :size="30" /> {{$t('Uploading...')}}
        </div>
        <div class="empty-list" v-if="!loading && !apartmentList.length">
          {{$t('No apartments were found')}}
        </div>
        <b-row>
          <b-col cols="12" :md="!toggleMap ? '12' : '6'" v-if="!loading && apartmentList.length">
            <AccommodationPaginatedList :list="apartmentList" :opened-map="toggleMap" @mapCardIndex="showInfoWindow"/>
          </b-col>
          <transition name="fade">
            <b-col cols="12" md="6" v-show="toggleMap" class="map-container">
              <div class="close-map my-4 d-md-none">
                <FontAwesomeIcon class="cursor-pointer" :icon="['fas', 'times']" color="#fff" size="lg" @click="toggleMap = !toggleMap"/>
              </div>
              <GmapMap :center="centerPosition" :zoom="12" class="apartments-map" ref="map">
                <GmapMarker v-for="(apartment, index) in apartmentList" :icon="apartmentMarker" :key="index" :position="apartmentPosition(apartment)"
                            @click="showInfoWindowAndScroll(index)">
                  <GmapInfoWindow v-if="toggleInfo && toggleInfoIndex === index" :options="{ pixelOffset: { width: 0, height: 0 } }" @closeclick="toggleInfo = false">
                    <div class="mr-4">
                      {{`${apartment.name} - ${apartment.addressStreet}, ${apartment.addressZipCode}`}}
                    </div>
                  </GmapInfoWindow>
                </GmapMarker>
              </GmapMap>
            </b-col>
          </transition>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
import AccommodationPaginatedList from '@/components/accomodations/AccommodationPaginatedList'
import Header from '@/components/Header'
import RFSelect from '@/components/forms/RFSelect'
import RFMonthPicker from "@/components/forms/RFMonthPicker";
import RFButton from "@/components/forms/RFButton";
import RFValidation from "@/components/forms/RFValidation";
import RFLoader from "@/components/forms/RFLoader";
import { apartmentService } from '@/services'
import {headerTypes} from '@/enums'
import moment from "moment";

export default {
  name: 'AccommodationsPage',
  components: {
    Header,
    RFSelect,
    RFMonthPicker,
    RFValidation,
    RFButton,
    RFLoader,
    AccommodationPaginatedList,
  },
  data() {
    return {
      headerTypes,
      apartmentList: [],
      toggleMap: false,
      toggleInfo: false,
      showFilters:false,
      toggleInfoIndex: null,
      sorting: null,
      apartmentMarker: null,
      specialDateFormat: true,
      searchParams: {
        startDate: null,
        endDate: null,
      },
      loading:false
    }
  },
  computed: {
    centerPosition() {
      return {
        lat: this.apartmentList.length ? this.apartmentList[0].addressLatitude :  45.4640976,
        lng: this.apartmentList.length ? this.apartmentList[0].addressLongitude : 9.1897378,
      }
    },
    currentMonth() {
      return moment().format('YYYY-MM')
    }
  },
  async beforeMount() {
    this.loading = true
    this.apartmentList = await this.getApartmentList()
    this.sortList('asc')
    this.loading = false
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.map.$mapPromise.then(map => {
        this.apartmentMarker = {
          url: '/images/green_roof.png',
          scaledSize: new google.maps.Size(30, 20),
        }
      })
    })
  },
  methods: {
    async getApartmentList() {
      return await apartmentService.getFilteredApartments(this.searchParams.startDate,this.searchParams.endDate)
    },
    apartmentPosition(apartment) {
      return {
        lat: apartment.addressLatitude,
        lng: apartment.addressLongitude,
      }
    },
    showInfoWindow(index) {
      this.toggleInfo = true
      this.toggleInfoIndex = index
    },
    showInfoWindowAndScroll(index) {
      this.showInfoWindow(index)
      let element = document.getElementById(`${index}`)
      let position = element.getBoundingClientRect();
      window.scrollTo({top: position.top + window.scrollY - 110, behavior: 'smooth'});
    },
    sortList(sorting) {
      if (this.apartmentList.length) {
        switch (sorting) {
          case 'asc':
            this.apartmentList.sort((firstItem, secondItem) => parseFloat(firstItem.monthlyAmount) - parseFloat(secondItem.monthlyAmount))
            break

          case 'desc':
            this.apartmentList.sort((firstItem, secondItem) => parseFloat(secondItem.monthlyAmount) - parseFloat(firstItem.monthlyAmount))
            break
        }
      }
    },
    async search() {
      const isValid = await this.$refs.validator.validate()

      if (isValid) {
        this.loading = true
        this.apartmentList = await apartmentService.getFilteredApartments(this.searchParams.startDate,this.searchParams.endDate)
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.apartments {
  .apartments-content {
    background-color: $gray;
    margin-top: 50px;
    padding: 30px 0;

    .sort-title {
      @include font-style($montserrat-bold, 'bold', $font-14);
      color: $dark-blue;
      display: inline-block;
    }
    .toggle-map {
      @include font-style($montserrat-bold, 'bold', $font-14);
      color: $dark-blue;
      text-transform: uppercase;
      cursor: pointer;
    }
    .map-container {
      position: sticky;
      top: 125px;
      left: 0;
      transform: translate(0, 0);
      background-color: $black;
      height: 100vh;
      z-index: 100;
      width: 100%;

      .close-map {
        text-align: right;
      }
      .apartments-map {
        width: 100%;
        height: calc(100vh - 86px);
      }
    }
    .booking-form-container {
          background-color: $dark-blue;
          padding: 15px;
          position: relative;
          z-index: 5;
          &:before {
            content: '';
            height: 100%;
            background-color: $dark-blue;
            position: absolute;
            top: 0;
            z-index: -1;
          }
          &:before {
            right: 50%;
          }

          .booking-label {
            @include font-style($montserrat-bold, 'bold', $font-14);
            color: $white;
            margin-top: 20px;
            white-space: nowrap;
          }
    }
  }

}

@media screen and (min-width: map-get($grid-breakpoints, 'md')) {
  .apartments {
    .apartments-content {
      .map-container {
        position: sticky;
        top: 110px;
        left: 0;
        transform: translate(0, 0);
        background-color: transparent;
        z-index: 25;
        height: 110px;

        .apartments-map {
          width: 100%;
          height: 400px;
        }
      }
    }
  }
}

@media screen and (min-width: map-get($grid-breakpoints, 'sm')) and (max-width: map-get($grid-breakpoints, 'md')) {
  .apartments {
    .apartments-content {
      .map-container {
        transform: translate(0, 0);
        position: relative;
        left: 0;
        top: 20px;
        z-index: 0;
      }
    }
  }
}
</style>
